$(document).ready(function() {


    $(document).on('click', '.toggleMenu', function(event) {
      event.preventDefault();
      $('#mobileMenuToggle').find('.toggleMenu').removeClass('active');
      $(this).addClass('active');

      var idToShow = $(this).data('show');

      $('#lunchRestaurant,#lunchTorget').hide();

      $('.mobile-menu-toggle-wrapper').toggleClass('brown-background');
      $('#' + idToShow).show();
    });

    $('.triggerMenu').on('click', function(event) {
      event.preventDefault();

      var idToShow = $(this).data('show');
      $('#' + idToShow).show();
      console.log('')
      $('.hamburger').addClass('is-active');
    });

    $('.closeModal').on('click', function(event) {
      event.preventDefault();
      /* Act on the event */
      $('.hamburger').removeClass('is-active');

      var $modalNavigation = $(this).closest('.modal-navigation');
      $modalNavigation.addClass('hiding-modal');
      setTimeout(function() {
        $modalNavigation.hide();
        $modalNavigation.removeClass('hiding-modal');
      }, 200);
    });

    // $(window).scroll(function(){
    //     if ($('.menu-menu').length > 0) {
    //       var z = $('.intro-part').css('z-index');
    //       $('.intro-part').css('z-index', z == 1 ? 2 : 1);
    //     }
    // });

});

$(window).on('load', function(event) {
  document.getElementById("cornyOverlay").style.height = document.body.offsetHeight + 'px';

  window.onresize = function(event) {
    document.getElementById("cornyOverlay").style.height = document.body.offsetHeight + 'px';
  };
});